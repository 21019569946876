<template>
  <base-page
    label="Загрузка чеков"
    :width="width"
    @click:back-button="$router.push({ name: 'bonus-program' })"
  >
    <div class="upload-check-input mt-6">
      <div class="upload-check-input__wrapper">
        <div class="upload-check-input__label">Чеки</div>

        <base-drag-n-drop
          :max-size="5 * 1024 * 1024"
          :removed-file="Boolean(selectedFile)"
          accepted-file-types="text/plain"
          @file-selected="handleFileSelected"
        />

        <base-button class="mt-6" is-new primary :disabled="!hasFile" extra-small @click="uploadCheck">
          Загрузить
        </base-button>
      </div>
    </div>

    <div class="upload-check-history mt-8">
      <base-table label="История загрузок" :columns="columns" :data="result">
        <template #uploadStatus="{ value }">
          <base-chip
            small
            :green="value === 'success'"
            :red="value === 'error'"
            :value="value === 'success' ? 'Успех' : 'Беда'"
          />
        </template>
      </base-table>
    </div>

    <upload-status-modal />
  </base-page>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import BaseButton from "@/components/BaseButton/BaseButton.vue";
import BaseTable from "@/components/BaseTable/ui/BaseTable.vue";
import BasePage from "@/components/BasePage/ui/base-page.vue";
import { request } from "@/components-js/requestSrv";
import { format as dateFormat } from "date-fns";
import { ru } from "date-fns/locale";
import BaseChip from "@/components/BaseChip/BaseChip.vue";
import BaseDragNDrop from "@/components/BaseDragNDrop/ui/base-drag-n-drop.vue";
import UploadStatusModal from "@/views/BonusProgramPage/ui/upload-status-modal.vue";
import { EVENTS } from "@/const";
import emitter from "@/lib/helpers/emitter";

const { width } = defineProps({
  width: { type: String, default: "510px" },
});

const selectedFile = ref(null);
const result = ref([]);
const hasFile = ref(false);

const columns = computed(() => [
  {
    id: "date",
    label: "Дата",
    format: row => {
      return dateFormat(new Date(row.dateTime), "dd.MM.yy", { locale: ru });
    },
  },
  {
    id: "time",
    label: "Время",
    format: row => {
      return dateFormat(new Date(row.dateTime), "HH:mm", { locale: ru });
    },
  },
  {
    id: "fileName",
    label: "Имя файла",
  },
  {
    id: "uploadStatus",
    label: "Статус",
  },
]);

const handleFileSelected = uploadedFile => {
  selectedFile.value = uploadedFile;

  hasFile.value = uploadedFile && typeof uploadedFile === "object";
};

const uploadCheck = () => {
  emitter.emit(EVENTS.SHOW_MODAL, {
    name: "upload-status",
    data: {
      status: false,
      fileName: "message.txt",
      uploadedPointsEarnings: [
        {
          pointName: "Ленина",
          period: "" || [],
          earning: 0,
        },
      ],
    },
  });

  selectedFile.value = null;
};

const mockData = [
  {
    dateTime: "2024-03-20T10:30:00",
    fileName: "check_001.txt",
    uploadStatus: "success"
  },
  {
    dateTime: "2024-03-19T15:45:00",
    fileName: "check_002.txt",
    uploadStatus: "success"
  },
  {
    dateTime: "2024-03-18T09:15:00",
    fileName: "check_003.txt",
    uploadStatus: "error"
  },
  {
    dateTime: "2024-03-17T14:20:00",
    fileName: "check_004.txt",
    uploadStatus: "success"
  }
];

const getData = async () => {
  //result.value = await request("/api/sales/salesuploadtest", "GET");
  // Имитация задержки API
  await new Promise(resolve => setTimeout(resolve, 500));
  result.value = mockData;
};

onMounted(() => {
  getData();
});
</script>

<style scoped lang="scss">
@import "@/styles/ui/fontSize";

.upload-check-input {
  &__label {
    @include add-font-face("body-2", var(--color-neutral-600), true, true);
  }

  &__field {
    display: flex;
    justify-content: center;
    gap: 5px;
    height: 40px;
    padding: var(--sp-ex-10);

    margin-top: var(--sp-ex-6);
    border-radius: 5px;
    border: 2px dashed var(--color-neutral-200);
    background: var(--color-white);

    cursor: pointer;
  }
}
</style>
